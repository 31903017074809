// React imports
import React, { useState, useEffect } from 'react';

import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import { SlideTransition } from '../SlideTransition';


const Alert = React.forwardRef(({ onClose, severity, sx, children , action , iconMapping}, ref) => (
  <MuiAlert
    elevation={6}
    ref={ref}
    variant='filled'
    onClose={onClose}
    severity={severity}
    sx={sx}
    action={action}
    iconMapping={iconMapping}
  >
    {children}
  </MuiAlert>
));

const ErrorSnackBar = ({ message, setMessage, autoHideDuration , onPatchClick , sx , page , buttonDisabled}) => {
  const [open, setOpen] = useState(false);
  const showActionButton = page === 'editMapping' || page === 'quality';
  const  showCustomIcon = showActionButton ? <WarningAmberOutlinedIcon fontSize='inherit' /> : null; 

  const handleOpen = () => setOpen(true);

  const handleClose = () => {
    setOpen(false);
    setMessage('');
  };

  useEffect(() => {
    if(showActionButton) {
      if (message !== '') {
        setOpen(true);
      } else {
        setOpen(false);
      }
    }
    handleOpen();
   
  }, [message]);

  const handleCloseForEditQuality = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  }

  return (
    <Snackbar
      open={open}
      TransitionComponent={SlideTransition}
      autoHideDuration={autoHideDuration}
      onClose={showActionButton ? handleCloseForEditQuality : handleClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      message={message}
    >
      <Alert onClose={handleClose} severity='error' sx={{ width: '100%'  , ...sx}}  action={
        showActionButton && (
          <Button
            size='small'
            onClick={onPatchClick}
            sx={{
              color: 'white',
              backgroundColor: buttonDisabled ? "#DEDEDE" : '#FF823C',
              // Remove hover effect
              '&:hover': {
                backgroundColor: '#FF823C', 
                color: 'white', 
                borderColor: 'transparent',
                textDecoration: 'none', 
              },
            }}
            disabled={buttonDisabled}
          >
            Save Changes
          </Button>
        )
      }
      iconMapping={showCustomIcon ? { error: <WarningAmberOutlinedIcon fontSize="inherit" /> } : <ErrorOutlineOutlinedIcon fontSize="inherit" />}
      >
        {message}
      </Alert>
    </Snackbar>
  );
};

export { ErrorSnackBar };
