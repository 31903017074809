/* eslint-disable no-use-before-define */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState , useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
// import { useSelector, useDispatch } from 'react-redux';

import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Stack,
  TableFooter,
} from '@mui/material';
// eslint-disable-next-line import/no-extraneous-dependencies
import { visuallyHidden } from '@mui/utils';

const monthOrder = {
  january: 0,
  february: 1,
  march: 2,
  april: 3,
  may: 4,
  june: 5,
  july: 6,
  august: 7,
  september: 8,
  october: 9,
  november: 10,
  december: 11,
};

const compareNumbers = (num1, num2) => num1 - num2;

// changed the code as demo-dashboard is going blank
// const extractNumericValue = (value) => value?.replace(/[^\d]/g, '');

const extractNumericValue = (value) => {
  if (typeof value === 'string') {
    return value.replace(/[^\d]/g, '');
  }
  return value;
};

function descendingComparator(a, b, orderBy) {
  const valueA = a[orderBy];
  const valueB = b[orderBy];
  const isFirstCharNumeric = /^[0-9]/.test(valueA);
  if (isFirstCharNumeric) {
    const [num1, num2] = [
      extractNumericValue(valueA),
      extractNumericValue(valueB),
    ];
    return compareNumbers(num1, num2);
    // eslint-disable-next-line no-else-return
  } else {
    if (valueB < valueA) {
      return -1;
    }
    if (valueB > valueA) {
      return 1;
    }
    return 0;
  }
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator, sortingOrder, orderBy) {
  if (orderBy.toLowerCase() === 'month') {
    return array.reduce((acc, el) => {
      acc[
        sortingOrder === 'desc'
          ? 11 - monthOrder[el.Month.toLowerCase()]
          : monthOrder[el.Month.toLowerCase()]
      ] = el;
      return acc;
    }, []);
  }

  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis?.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis?.map((el) => el[0]);
}

const EnhancedTableHead = ({
  headCells,
  order,
  orderBy,
  onRequestSort,
  verticalBorders = false,
  setResizable,
  location
}) => {
  const tableHeadRef = useRef(null);
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  const [columnWidths, setColumnWidths] = useState(headCells?.map(() => 50));
  const resizingColumnIndex = useRef(null);
  const cellRefs = useRef([]);
  const startX = useRef(null);
  const startWidth = useRef(null);
  const isResizing = useRef(false);
  const resizeThreshold = 5;
  useEffect(() => {
    const initialWidths = cellRefs.current?.map(
      (cell) => cell.getBoundingClientRect().width
    );
    setColumnWidths(initialWidths);
  }, []);

  useEffect(() => {
    const initialWidths = cellRefs.current?.map(
      (cell) => cell.getBoundingClientRect().width
    );
    setColumnWidths(initialWidths);
  }, []);
  // eslint-disable-next-line no-unused-vars
  const handleMouseDown = (event, index) => {
    // Directly measure the current width of the table cell
    const cell = event.target.closest('th');
    if (cell) {
      const cellRect = cell.getBoundingClientRect();
      startWidth.current = cellRect.width;
    } else {
      startWidth.current = columnWidths[index];
    }
    resizingColumnIndex.current = index;
    startX.current = event.clientX;
    isResizing.current = true;
    setResizable(index, true);
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
    event.preventDefault();
  };
  const handleMouseMove = (event) => {
    if (resizingColumnIndex.current !== null) {
      const deltaX = event.clientX - startX.current;
      if (!isResizing.current && Math.abs(deltaX) > resizeThreshold) {
        isResizing.current = true;
        setResizable(resizingColumnIndex.current, true);
      }
      if (isResizing.current) {
        const newWidth = startWidth.current + deltaX;
        setColumnWidths((prevWidths) => {
          const newWidths = [...prevWidths];
          newWidths[resizingColumnIndex.current] = Math.max(newWidth, 20);
          // setMaxWidth(resizingColumnIndex.current, Math.max(newWidth, 20));
          return newWidths;
        });
      }
    }
  };
  const handleMouseUp = () => {
    if (resizingColumnIndex.current !== null) {
      setResizable(resizingColumnIndex.current, false);
      resizingColumnIndex.current = null;
    }
    document.removeEventListener('mousemove', handleMouseMove);
    document.removeEventListener('mouseup', handleMouseUp);
    startX.current = null;
    startWidth.current = null;
    isResizing.current = false;
  };
  return (
    <TableHead  ref={tableHeadRef}>
      <TableRow className={verticalBorders ? '' : 'noVerticalBorders'}>
        {headCells?.map((headCell , index) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            sortDirection={orderBy === headCell.id ? order : false}
            // eslint-disable-next-line no-return-assign
            ref={(el) => (cellRefs.current[index] = el)}
            sx={ !location?.pathname.includes("users") ? {
              minWidth: columnWidths[index]
                ? `${columnWidths[index]}px`
                : '50px',
              width: columnWidths[index] ? `${columnWidths[index]}px` : '50px',
              position: 'relative !important',
              border: '1px solid #DEDEDE',
              padding: '16px 0px 16px 16px !important',
              borderTop: '0px !important',
              maxWidth: '250px',
              transition: 'width 0.1s ease',

              '&:first-of-type': {
                borderLeft: '0px',
              },
            } : null}
          >
            {/* label exists and isImage is false */}
            {headCell.label && !headCell.isImage && (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
                sx={{
                  paddingRight:"30px",
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  minWidth: '100px',
                  maxWidth: columnWidths[index]
                    ? `${columnWidths[index]}px`
                    : '250px',
                  display: 'block',
                  '& svg': {
                    position: 'absolute',
                    right: '0',
                    padding: '0 5px',
                    marginRight: '0',
                    width: `calc(1rem + 10px)`,
                  },
                }}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc'
                      ? 'sorted descending'
                      : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            )}
            {/* label exists and isImage is true */}
            {headCell.label && headCell.isImage && headCell.label}
            {!location?.pathname.includes("users") ? (    <Box
              onMouseDown={(event) => handleMouseDown(event, index)}
              sx={{
                position: 'absolute',
                right: -5,
                // left: -5,
                top: 0,
                bottom: 0,
                width: '10px',
                cursor: 'col-resize',
                zIndex: 2,
              }}
            />) : null }
        
          </TableCell>
        ))}
        {!location?.pathname.includes("users") ? (
          <TableCell
          sx={{
            flexGrow: 1,
            width: 'auto',
            position: 'relative !important',
            border: '1px solid #DEDEDE',
            padding: '16px 0px 16px 16px !important',
            borderTop: '0px !important',
          }}
        >
          <Box
            onMouseDown={(event) => handleMouseDown(event)}
            sx={{
              position: 'absolute',
              right: -5,
              // left: -5,
              top: 0,
              bottom: 0,
              width: '10px',
              cursor: 'col-resize',
              zIndex: 2,
            }}
          />
        </TableCell>
        ) : null }

      </TableRow>
    </TableHead>
  );
};

// headcells is an array of objects with the following fields
// id: string. name of the field in 'rows' object
// numeric: boolean. The corresponding field will be included in the total on the footer if true.
// label: string. Label to be displayed.
// disablePadding: Boolean. Padding is reduced when true.
// formatFunction: Callback returning the desired component/string to be display for the value.
//                 The callback will recieve the current row object as an argument.
// totalFunction?: Callback returning the total of correspoding field. The callback will recieve all rows as an argument

// rowIdField: this is the field whose value is used when 'key' are required. Must be unique

// rows: list of objects. This is the data to be displayed in the table.

// the function to seperate the formatFunction and totalFunction from the headCells to save in redux
// const transformHeadCellsWithValues = (array) => {
//   // Array to store format functions with identifiers
//   const formatFunctions = [];

//   // Create a new array without the 'formatFunction' property in each object
//   const transformedArray = array.map((headCell) => {
//     const { formatFunction, ...rest } = headCell;
//     if (formatFunction) {
//       formatFunctions.push({
//         label: headCell.label,
//         formatFunction,
//       });
//     }
//     return rest;
//   });
//   return { transformedArray, formatFunctions };
// };

const CustomTable = ({
  headCells,
  rows,
  // rowIdField,
  rowsPerPage = 10,
  verticalBorders = false,
  displayFooter = true,
  defaultOrder = 'asc',
  defaultOrderBy = 'none',
  enablePagination = true,
  style,
  linkRows = false,
  linkFormatter = () => 'xxx',
  linkStateFormatter = (row) => row,
  filterFormettedFunctionContainer,
  columnsTobeRender,
  setResizable,
  setMaxWidth,
  styleBodyCell
}) => {
  const location = useLocation()
  const [order, setOrder] = useState(defaultOrder);
  const [orderBy, setOrderBy] = useState(defaultOrderBy);
  const [page, setPage] = useState(0);
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  useEffect(() => {
    setOrder(defaultOrder);
    setOrderBy(defaultOrderBy);
    setPage(0); // Reset page when rows change
  }, [rows, defaultOrder, defaultOrderBy]);

  return (
    <Stack height='100%'>
      <TableContainer sx={style}>
        <Table stickyHeader aria-labelledby='tableTitle' size='small'>
          <EnhancedTableHead
            headCells={columnsTobeRender || headCells}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={rows?.length}
            verticalBorders={verticalBorders}
            setResizable={setResizable}
            setMaxWidth={setMaxWidth}
            location={location}
          />
          {location.pathname.includes("users") ? (
           <TableBody>
            {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
            {stableSort(rows, getComparator(order, orderBy), order, orderBy)
              ?.slice(
                enablePagination ? page * rowsPerPage : 0,
                enablePagination
                  ? page * rowsPerPage + rowsPerPage
                  : rows.length
              )
              .map((row , indx) => (
                <TableRow
                  hover
                  tabIndex={-1}
                  // key={row[`${rowIdField}`]}
                  key={indx}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  className={verticalBorders ? '' : 'noVerticalBorders'}
                >
                  {headCells?.map((headCell, index) => {
                    if (index === 0) {
                      return (
                        <TableCell
                          // key={headCell.id}
                          key={index} 
                          component='th'
                          scope='row'
                          align={headCell.alignRight ? 'right' : 'left'}
                        >
                          {headCell.formatFunction(row)}
                        </TableCell>
                      );
                    }
                    return (
                      <TableCell
                        // key={headCell.id}
                        key={index}
                        align={headCell.alignRight ? 'right' : 'left'}
                      >
                        {headCell.formatFunction(row)}
                       
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))}
            {emptyRows > 0 && (
              <TableRow
                style={{
                  height: 53 * emptyRows,
                }}
                className={verticalBorders ? '' : 'noVerticalBorders'}
              >
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>) : (
            <TableBody>
            {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
            {stableSort(rows, getComparator(order, orderBy), order, orderBy)
              ?.slice(
                enablePagination ? page * rowsPerPage : 0,
                enablePagination
                  ? page * rowsPerPage + rowsPerPage
                  : rows.length
              )
              .map((row, indx) => (
                <TableRow
                  hover
                  tabIndex={-1}
                  // key={row[`${rowIdField}`]}
                  key={indx}
                  sx={{
                    '&:last-child td, &:last-child th': { border: 0 },
                    textDecoration: 'none',
                  }}
                  className={verticalBorders ? '' : 'noVerticalBorders'}
                  {...(linkRows
                    ? {
                        component: Link,
                        to: linkFormatter(row),
                        state: {
                          ...linkStateFormatter(row),
                          page,
                        },
                      }
                    : {})}
                >
                  {(columnsTobeRender || headCells)?.map((headCell, index) => {
                    const formatFunctionObj =
                      filterFormettedFunctionContainer?.find(
                        (el) =>
                          el?.label.toLowerCase() ===
                            headCell?.label.toLowerCase() ||
                          el?.Label?.toLowerCase() ===
                            headCell?.label?.toLowerCase()
                      );

                    const formattedValue =
                      formatFunctionObj &&
                      formatFunctionObj.formatFunction(row);
                    
                     return (
                      <TableCell
                        // key={headCell.id}
                        key={index}
                        align={headCell?.alignRight ? 'right' : 'left'}
                           sx={{
                              ...styleBodyCell,
                              maxWidth: '200px',
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                            }}
                      >
                        {formattedValue}
                     
                      </TableCell>
                    );
                  })}
                       <TableCell
                            sx={{
                              ...styleBodyCell,
                              maxWidth: '200px',
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                            }}
                              />
                </TableRow>
              ))}
            {emptyRows > 0 && (
              <TableRow
                style={{
                  height: 53 * emptyRows,
                }}
                className={verticalBorders ? '' : 'noVerticalBorders'}
              >
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>)}
        
          {displayFooter ? (
            <TableFooter sx={{ flexShrink: 0 }}>
              <TableRow className={verticalBorders ? '' : 'noVerticalBorders'}>
                <TableCell component='th' scopr='row' align='left'>
                  Total
                </TableCell>
                {headCells?.slice(1)?.map((headCell) => {
                  if (headCell.numeric) {
                    return (
                      <TableCell key={headCell.id} align='right'>
                        {headCell.totalFunction(rows)}
                      </TableCell>
                    );
                  }
                  return <TableCell key={headCell.id} align='right' />;
                })}
              </TableRow>
            </TableFooter>
          ) : null}
        </Table>
      </TableContainer>
      {/* show pagination only when rows.length is greater than rowsPerPage */}
      {rows?.length > rowsPerPage && enablePagination ? (
        <TablePagination
          // single value in array hides the rowsPerPage selector
          rowsPerPageOptions={[rowsPerPage]}
          component='div'
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
        />
      ) : null}
    </Stack>
  );
};

const DashboardCustomTable = ({
  headCells,
  rows,
  rowIdField,
  rowsPerPage = 10,
  verticalBorders = false,
  displayFooter = true,
  totalRow,
  defaultOrder = 'asc',
  defaultOrderBy = 'none',
  linkRows = false,
  linkFormatter = () => 'xxx',
  linkStateFormatter = (row) => row,
}) => {
  const [order, setOrder] = useState(defaultOrder);
  const [orderBy, setOrderBy] = useState(defaultOrderBy);
  const [page, setPage] = useState(0);
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  useEffect(() => {
    // reset order and order by when rows change
    setOrder(defaultOrder);
    setOrderBy(defaultOrderBy);
  }, [rows]);

  return (
    <Stack height='100%' overflow='hidden'>
      <TableContainer>
        <Table stickyHeader aria-labelledby='tableTitle' size='small'>
          <EnhancedTableHead
            headCells={headCells}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={rows.length}
            verticalBorders={verticalBorders}
          />
          <TableBody>
            {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
            {stableSort(rows, getComparator(order, orderBy), order, orderBy)
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => (
                <TableRow
                  hover
                  tabIndex={-1}
                  key={row[`${rowIdField}`]}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  className={verticalBorders ? '' : 'noVerticalBorders'}
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...(linkRows
                    ? {
                        component: Link,
                        to: linkFormatter(row),
                        state: {
                          ...linkStateFormatter(row),
                          page,
                        },
                      }
                    : {})}
                >
                  {headCells?.map((headCell, index) => {
                    if (index === 0) {
                      return (
                        <TableCell
                          key={headCell.id}
                          component='th'
                          scope='row'
                          align={headCell.alignRight ? 'right' : 'left'}
                        >
                          {headCell.formatFunction(row)}
                        </TableCell>
                      );
                    }
                    return (
                      <TableCell
                        key={headCell.id}
                        align={headCell.alignRight ? 'right' : 'left'}
                      >
                        {headCell.formatFunction(row)}
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))}
            {emptyRows > 0 && (
              <TableRow
                style={{
                  height: 53 * emptyRows,
                }}
                className={verticalBorders ? '' : 'noVerticalBorders'}
              >
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
          {displayFooter ? (
            <TableFooter sx={{ flexShrink: 0 }}>
              <TableRow className={verticalBorders ? '' : 'noVerticalBorders'}>
                <TableCell component='th' scopr='row' align='left'>
                  Total
                </TableCell>
                {headCells.slice(1)?.map((headCell) => {
                  if (headCell.numeric) {
                    return (
                      <TableCell key={headCell.id} align='right'>
                        {/* {headCell.totalFunction(rows)} */}
                        {headCell.totalFunction(rows, totalRow)}
                      </TableCell>
                    );
                  }
                  return <TableCell key={headCell.id} align='right' />;
                })}
              </TableRow>
            </TableFooter>
          ) : null}
        </Table>
      </TableContainer>
      {/* show pagination only when rows.length is greater than rowsPerPage */}
      {rows.length > rowsPerPage ? (
        <TablePagination
          // single value in array hides the rowsPerPage selector
          rowsPerPageOptions={[rowsPerPage]}
          component='div'
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
        />
      ) : null}
    </Stack>
  );
};
export { CustomTable, DashboardCustomTable };
