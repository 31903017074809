import { styled } from '@mui/system';

// MUI imports
import { Paper } from '@mui/material';

const StyledDetail = styled(Paper)(
    ({ theme }) => `
  &.projectDetail{
    margin: 12px 10px;
    border-radius: 12px;
    border: 1px solid ${theme.palette.border.main};
    &:hover{
        border: 1px solid ${theme.palette.primary.main}
    }
    &:hover{
        .projectContent{
            border-top: 1px solid ${theme.palette.primary.main}
        }
        .allProjectOpen{
            border-top: 1px solid ${theme.palette.primary.main}
        }
    }
    .noteIcon{
        font-size: 42px;
        color: ${theme.palette.primary.main};
        margin-right: 4px;
    }
    .allProjectWrapper{
        padding: 14px 14px 0px 14px;
        min-height: 175px;
    }
    .projectHeading{
        font-size: 14px;
        font-weight: 500;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: #323C46;

    }
    .activeColor{
        font-size: 12px;
        background-color: #e3f9e3;
        padding: 5px;
        color: #1db494;
        font-weight: 600;
    }
    .deactiveColor{
        background-color: #fce7e5;
        padding: 5px;
        font-size: 12px;
        color: #e13f2a;
        font-weight: 500;
    }
    .gridUnderline{
        @media screen and (max-width: 480px) {
            width: 100%;
        }
    }
    .projectContent{
        margin-top: 10px;
        border-top: 1px solid ${theme.palette.border.main};
        .projectName{
            span{
                font-size: 14px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                 color: ${theme.palette.text.primary}
            }
        }
        .strikedProjectName{
            span{
                font-size: 14px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                text-decoration: line-through;
                 color: ${theme.palette.text.disabled};
            }
        }
    }
    .allProjectOpen{
        border-top: 1px solid ${theme.palette.border.main};
        text-align: center;
        font-size: 14px;
        a{
            text-decoration: none;
        }
    }
    a{
        text-decoration: none;
        &:active{
            color:${theme.palette.text.primary};
        }
    }
  }

&.addProjectCard{
    height: 215px;
    color: #323C46;
    font-size: 14px;
    font-weight: 500;
    @media screen and (max-width: 480px) {
        display: none;
    }
}

&.emptyProject {
    border: 1px solid ${theme.palette.secondary.main};

    &:hover{
    border: 1px solid ${theme.palette.secondary.main};
    }
    .projectContent{
        border-top: 1px solid ${theme.palette.secondary.main};
    }
    .allProjectOpen{
        border-top: 1px solid ${theme.palette.secondary.main};
    }
    
    &:hover{
        .projectContent{
            border-top: 1px solid ${theme.palette.secondary.main};
        }
        .allProjectOpen{
            border-top: 1px solid ${theme.palette.secondary.main};
        }
    }

    .noteIcon{
        font-size: 42px;
        color: ${theme.palette.secondary.main};
        margin-right: 4px;
    }
}
.desktopAddProject{
    .MuiSvgIcon-root{
        color: #ffffff;
    }
}
  `
);

export { StyledDetail };
