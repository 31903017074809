import React from 'react';
// MUI imports
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
} from '@mui/material';

// Styled component imports
import { Link, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { StyledItem } from '../StyledComponents/StyledCommon';
import { updateToggleButton } from '../../Redux/CustomizableHeadcells/All-Items/slice';

const renderCell = (val) =>
  typeof val === 'object' ? JSON.stringify(val) : val;
const EnhanceItemDetails = ({ item }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const getBack = () => {
    if (location.pathname === '/feed-management/feeds/enhance/item-details') {
      return '../feeds/enhance';
    }
    if (location.pathname === '/feed-management/feeds/preview/item-details') {
      return '../feeds/preview';
    }
    if (
      location.pathname === '/feed-management/imports/all-items/item-details'
    ) {
      return '../all-items';
    }
    if (
      location.pathname ===
        '/feed-management/feeds/enhance/filtered-items/item-details' &&
      location.search.includes('toggled=Removed%20Items')
    ) {
      dispatch(updateToggleButton('Removed Items'));
      return '/feed-management/feeds/enhance/filtered-items';
    }

    if (
      location.pathname ===
        '/feed-management/feeds/enhance/filtered-items/item-details' &&
      location.search.includes('toggled=Remaining%20Items')
    ) {
      dispatch(updateToggleButton('Remaining Items'));
      return '/feed-management/feeds/enhance/filtered-items';
    }
    if (
      location.pathname ===
      '/feed-management/feeds/enhance/remaining-items/item-details'
    ) {
      return '/feed-management/feeds/enhance/remaining-items';
    }
    if (
      location.pathname ===
      '/feed-management/feeds/enhance/affected-items/item-details'
    ) {
      return '/feed-management/feeds/enhance/affected-items';
    }
    return null;
  };

  if (item) {
    return (
      <>
        <StyledItem>
          <Table aria-label='simple table'>
            <TableHead>
              <TableRow className='itemRow'>
                <TableCell sx={{ fontWeight: '500' }}>Field</TableCell>
                <TableCell sx={{ fontWeight: '500' }}>Value</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.keys(item).map((key) => (
                <TableRow key={key} className='itemRow'>
                  {key.length > 35 ? (
                    <TableCell
                      component='th'
                      scope='row'
                      sx={{
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        maxWidth: '200px',
                      }}
                    >
                      {key}
                    </TableCell>
                  ) : (
                    <TableCell component='th' scope='row'>
                      {key}
                    </TableCell>
                  )}

                  {key === 'image_link' ? (
                    <TableCell>
                      <img src={item[key]} alt='item' height='50' width='50' />
                    </TableCell>
                  ) : (
                    <TableCell>{renderCell(item[key])}</TableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </StyledItem>
        <Button
          variant='outlined'
          component={Link}
          sx={{
            width: '200px',
            margin: '30px auto',
            display: 'flex',
          }}
          to={getBack()}

        >
          Back
        </Button>
      </>
    );
  }
  return 'Error';
};
export { EnhanceItemDetails };
