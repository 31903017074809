import { styled } from '@mui/system';

// MUI imports
import {
  AppBar,
  Modal,
  TableContainer,
  Button,
  TableHead,
  Box,
  Stack,
  ToggleButtonGroup,
  FormControl,
  List,
  Paper,
  Table,
} from '@mui/material';

// AddButton & CancelSaveButtonModal component style
const StyledButtonModal = styled('div')(
  ({ theme }) => `
  
  .buttonsWrap{
    margin: 65px;
  }
  .addProjectButton{
    border-radius: 20px;
    height: 42px;
    background-color: ${theme.palette.primary.main};
    color:  ${theme.palette.background.main};
    &:hover{
        background-color: ${theme.palette.primary.main};
        color: ${theme.palette.background.main}; 
    }
    svg{
      color: #ffffff !important;
      font-szie: 18px;
    }       
  }
  .stylingButton{
    width: 100%;
    margin: 25px 10px 25px 10px;
  }
  .desktopAddProject{
    height: 215px;
    width: 100%;
    display: flex;
    flex-direction: column;
    color: #323C46;
    svg{
      border-radius: 50%;
      color: #ffffff;
      font-size: 32px;
      margin-bottom: 5px;
      background-color: ${theme.palette.primary.main};
      padding: 5px;
    }
  }
`
);

const StyledFAModal = styled(Modal)(
  ({ theme }) => `
  overflow-y: scroll;
  z-index: 1000;
  .MuiBackdrop-root.MuiModal-backdrop{
    background-color: rgba(0, 0, 0, 0.1)!important;
    backdrop-filter: blur(2px)!important;
  }
    .modalBox{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: ${theme.palette.background.main};
        padding: 5px;
        box-shadow: ${theme.shadows[1]};
        min-height: 300px;
        min-width: 375px;
        border-radius: 12px;
        .modalHeading{
            text-align: center;
            border-bottom: 1px solid ${theme.palette.primary.main};
            padding: 18px 0px;
            color: ${theme.palette.text.primary}
        }
        .addProjectWrap{
            margin: auto 10px;
            &:hover .MuiFormLabel-root {
              color: #323c46;
            }
            .addProjectInput{
                margin-top: 50px;
                width: 400px;
            }
        }
    }
    .addProjectInput{
      input{
        height: 17px;
      }
    }
    &.copyCtModal{
      .MuiOutlinedInput-root.MuiInputBase-root{
        height: 40px;
      }
    }
    &.generateCtModal{
      .MuiOutlinedInput-root.MuiInputBase-root{
        height: 40px;
      }
    }
    .generateCategory{
      .MuiOutlinedInput-root{
        margin-top: 15px;
      }
      label{
        top: -12px;
      }
    }
    .columnDel{
      margin-top: 25px;
      color: #999999;
      border-radius: 4px;
      border: 1px solid ${theme.palette.border.main};
      padding: 2px;
    }
    .modalBox.longModal{
      top: 60%;
    }
`
);

// AddProject component style
const StyledModal = styled(Modal)(
  ({ theme }) => `
  overflow-y: scroll;
  z-index: 1000000;
  .MuiBackdrop-root.MuiModal-backdrop{
    background-color: rgba(0, 0, 0, 0.1)!important;
    backdrop-filter: blur(2px)!important;
  }
    .modalBox{     
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: ${theme.palette.background.main};
        padding: 5px;
        box-shadow: ${theme.shadows[1]};

        .modalHeading{
            text-align: center;
            border-bottom: 1px solid ${theme.palette.primary.main};
            padding: 18px 0px;
            color: ${theme.palette.text.primary}
        }
        .addProjectWrap{
            margin: auto 10px;
            &:hover .MuiFormLabel-root {
              color: #323c46;
            }
            .addProjectInput{
                margin-top: 50px;
                width: 400px;
            }
        }
    }
    .addProjectInput{
      input{
        height: 17px;
      }
    }
    &.copyCtModal{
      .MuiOutlinedInput-root.MuiInputBase-root{
        height: 40px;
      }
    }
    &.generateCtModal{
      .MuiOutlinedInput-root.MuiInputBase-root{
        height: 40px;
      }
    }
    .generateCategory{
      .MuiOutlinedInput-root{
        margin-top: 15px;
      }
      label{
        top: -12px;
      }
    }
    .columnDel{
      margin-top: 25px;
      color: #999999;
      border-radius: 4px;
      border: 1px solid ${theme.palette.border.main};
      padding: 2px;
    }
    .modalBox.longModal{
      top: 60%;
    }
`
);

const StyledCustomColumnModel = styled(Modal)(
  ({ theme }) => `
  // top: 150px;
  bottom: 500px;
  overflow-y: scroll;
  z-index: 1000;
  // z-index: 10;
  width: 100%;
  min-height:100%;
  min-width: 500px;
  border-radius: 12px;
  .MuiBackdrop-root.MuiModal-backdrop{
    background-color: rgba(0, 0, 0, 0.1)!important;
    backdrop-filter: blur(2px)!important;
  }
    .modalBox{
        position: absolute;
        overflow-x: hidden;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: ${theme.palette.background.main};
        padding: 5px;
        box-shadow: ${theme.shadows[1]};
        min-height: 300px;
        overflow-y: hidden;
        min-width:520px;
        padding: 25px;
        border-radius: 12px;
        .modalHeading{
            text-align: center;
            font-size: 18px;
            border-bottom: 1px solid ${theme.palette.primary.main};
            padding: 18px 0px;
            color: ${theme.palette.text.primary}
        }
        .modalSubHeading{
          text-align: left;
          font-size: 16px;
          margin-top: 20px;
          fontweight: 500;
          color: ${theme.palette.text.primary}
        }
        // .addbutton{
        //   margin-top: 25px;
        //   height: 42px;
        //   // width:100%;
        //   position: absolute !important;
        //   bottom: 80px;
        //   left: 22px;
        //   background-color: ${theme.palette.background.main};
        // }
        .errorButton{
          self-align: center;
          font-size: 12px;
          height: 42px;
          position: absolute !important;
          bottom: 50px;
          left: 130px;
          color: #E13F2A;
          font-weight: 600 !important;
         
        }
        .addProjectWrap{
            margin: auto 10px;
            &:hover .MuiFormLabel-root {
              color: #323c46;
            }
            .addProjectInput{
                // margin-top: 50px;
                width: 600px;
            }
        }
    }
    .addProjectInput{
      input{
        height: 17px;
      }
    }
    &.copyCtModal{
      .MuiOutlinedInput-root.MuiInputBase-root{
        height: 40px;
      }
    }
    &.generateCtModal{
      .MuiOutlinedInput-root.MuiInputBase-root{
        height: 40px;
      }
    }
    .generateCategory{
      .MuiOutlinedInput-root{
        margin-top: 15px;
      }
      label{
        top: -12px;
      }
    }
    .columnDel{
      color: #999999;
      border-radius: 4px;
      padding :0 !important;
      font-size: 20px;
    }
    .modalBox.longModal{
      top: 60%;
    }
    .projectSelect{
    
    height: 40px;
    width: 100%
     border:none !imporatant;
     .importDropdown{
        margin-top: 0px !important; 
      }
    }
`
);

// NavigationMenu component style
const StyledMenu = styled(AppBar)(
  ({ theme }) => `
  &.navMenu{
    width: fit-content;
    position: relative;
    background-color: ${theme.palette.background.main};
    .navItem{
      color: ${theme.palette.text.primary}; 
      &:hover{
        background-color: ${theme.palette.background.main};
      }
    }
    .navLinks{
      background-color: transparent;
      a{
      text-decoration: none;
      }
      button{
        font-size: 1rem;
        font-weight: 500;
        padding: 6px 15px;
        &:hover{
          color: #0096ff;
          &::after {
            content: '';
            position: absolute;
            bottom: -22px;
            left: 0;
            width: 100%;
            height: 6px;
            background-color: #0096ff;
          }
        }
      }
    }
    .active{
      button{
      color: #0096ff;
      &::after {
        content: '';
        position: absolute;
        bottom: -22px;
        left: 0;
        width: 100%;
        height: 6px;
        background-color: #0096ff;
      }
    }
  }
`
);

const StyledMobileList = styled(List)`
  a {
    text-decoration: none;
  }
  .MuiTypography-root {
    font-size: 18px;
    color: #0096ff;
    font-weight: 500;
  }
`;

// ImportedFile component style
const StyledTableContainer = styled(TableContainer)(
  ({ theme }) => `
  .summaryImport{
    border-right: none !important;
    border-bottom: 1px solid ${theme.palette.border.main};
    font-weight: 500;
  }
`
);

const StyledImported = styled(Paper)(
  ({ theme }) => `
 border: 1px solid ${theme.palette.border.main};
 border-radius: 8px;
 box-shadow: none !important;
 .MuiPaper-root.MuiPaper-elevation {
  box-shadow: none !important;
 }
 .importedButton{
  text-decoration: none;
  button{
    color: ${theme.palette.text.primary};
    &:hover{
      color: ${theme.palette.primary.main};
    }
  }

 }
 .MuiButton-root{
  &:hover{
    background-color: none !important;
  }
}
.importedButtonWrap{
  border-top: 1px solid ${theme.palette.border.main};
}
`
);

const StyledButton = styled(Button)(
  ({ theme }) => `
  &.combineImportBtn{
    border: 1px solid ${theme.palette.primary.main};
    margin: 15px;
     svg{
    color: #0096ff;
    fontSize: 20px;
  }
  }
 
`
);

// EditMappingTable component style
const StyledEditMappingBox = styled(Box)(
  ({ theme }) => `
  .mappingBody{
    border-right: none !important;
    .editMapAddfield{
      .importDropdown{
        height: 40px;
        width: 300px;
      }
    }
    .delBtn{
      border-radius: 4px;
      border: 1px solid ${theme.palette.border.main};
      padding: 2px;
    }
  }

  .mappingPagination{
    .MuiTablePagination-selectLabel{
      display: none;
    }
    .MuiInputBase-root{
      display: none;
    }
  }
`
);

// EditMappingTable component style
const StyledEnhcancedHead = styled(TableHead)(
  ({ theme }) => `
.enhancedHead{
  border-bottom: 1px solid ${theme.palette.border.main} !important;
  border-right: none !important;
  font-weight:500;
}
`
);

// EditMappingTable component style
const StyledStack = styled(Stack)`
  .editMapAddfield {
    width: auto !important;
    .importDropdown {
      height: 40px;
      width: 300px;
    }
  }
  .addNewEditMap {
    width: 200px;
    height: 42px;
  }
`;

// AddCategoryForm component style
const StyledCategoryTableContainer = styled(TableContainer)(
  ({ theme }) => `
  .categoryCell {
    border-right: 0px !important;
    border-bottom: 1px solid ${theme.palette.border.main};
    font-weight: 500;
  }
  .categorySelectBox{
    width: 200px;
    .MuiOutlinedInput-root.MuiInputBase-root{
      height: 40px;
    }
  }
  .categoryInput{
    width: 200px;
      input{
        height: 17px;
        cursor: pointer;
      }  
  }
  .itemNumber{
    width: 100px;
    cursor: not-allowed;
  }
  .categoryAutoComplete{
    width: 250px;
    .MuiOutlinedInput-root.MuiInputBase-root{
      height: 40px;
    }
    input{
      cursor: pointer;
    }
    .MuiInputBase-hiddenLabel{
      padding-top: 3px !important;
    }
  }
`
);

// EnhanceMainListTable component style
const StyledEnhanceTable = styled(Box)`
  .enhanceTableRow {
    th,
    td {
      border-right: none !important;
    }
  }
  thead {
    th {
      padding: 0rem 1rem;
    }
  }
`;

const StyledItem = styled(TableContainer)`
  .itemRow {
    th {
      border-right: none !important;
      border-bottom: 1px solid #dedede;
    }
    td {
      border-right: none;
    }
  }
`;

// ToggleButton component style
const StyledToggleButtonGroup = styled(ToggleButtonGroup)`
  &.statusToggleGroup {
    height: 44px;
    /* height: auto; */
    border: 1px solid #dedede;
    .statusToggle {
      min-width: 100px;
      border-radius: 30px;
      @media (max-width: 768px) {
        min-width: 80px;
      }
      &:not(:first-of-type) {
        margin-left: 0px !important;
      }
    }
    .MuiToggleButton-root.Mui-selected:first-of-type {
      border: 1px solid #1db949 !important;
      background-color: #ffffff;
      color: #1db949;
      min-width: 100px;
      border: none;
      @media (max-width: 768px) {
        min-width: 80px;
      }
      &:hover {
        border-radius: 30px;
      }
    }
    .MuiToggleButton-root.Mui-selected:nth-of-type(2) {
      border: 1px solid #e13f2a !important;
      background-color: #ffffff;
      color: #e13f2a;
      min-width: 100px;
      border: none;
      @media (max-width: 768px) {
        min-width: 80px;
      }
      &:hover {
        border-radius: 30px;
      }
    }
    .deactive.MuiToggleButton-root.Mui-selected:nth-of-type(2) {
      border: 1px solid #999999 !important;
      border-radius: 30px;
    }
  }
  .Mui-disabled {
    color: #999999 !important;
  }
  &.twoToggleParent {
    height: 44px;
    border: 1px solid #dedede;
    border-radius: 30px;

    .MuiToggleButton-root.Mui-selected {
      background-color: #0096ff;
      color: #ffffff;
      border-radius: 30px;
      min-width: 150px;
      @media (max-width: 768px) {
        min-width: 80px;
      }
      &:hover {
        color: #ffffff;
        background-color: #0096ff;
      }
    }
    .twoToggle1 {
      min-width: 135px;
      color: #323c46;
      border: 1px solid transparent;
      @media (max-width: 768px) {
        min-width: 80px;
      }
      &:hover {
        border-radius: 30px;
        border: 1px solid #0096ff;
        color: #0096ff;
      }
    }
    .twoToggle2 {
      min-width: 135px;
      color: #323c46;
      border: 1px solid transparent;
      @media (max-width: 768px) {
        min-width: 80px;
      }
      &:hover {
        border-radius: 30px;
        border: 1px solid #0096ff;
        background-color: #ffffff;
      }
    }
  }

  &.feedToggleParent {
    height: 44px;
    border: 1px solid #dedede;
    border-radius: 30px;

    .MuiToggleButton-root.Mui-selected {
      background-color: #0096ff;
      color: #ffffff;
      border-radius: 30px;
      min-width: 100px;
      @media (max-width: 768px) {
        min-width: 80px;
      }
      &:hover {
        color: #ffffff;
        background-color: #0096ff;
      }
    }
    .feedToggle1 {
      min-width: 100px;
      color: #323c46;
      border: 1px solid transparent;
      @media (max-width: 768px) {
        min-width: 80px;
      }
      &:hover {
        border-radius: 30px;
        border: 1px solid #0096ff;
        background-color: #ffffff;
      }
    }
    .feedToggle2 {
      min-width: 100px;
      color: #323c46;
      border: 1px solid transparent;
      @media (max-width: 768px) {
        min-width: 80px;
      }
      &:hover {
        border-radius: 30px;
        border: 1px solid #0096ff;
        background-color: #ffffff;
      }
    }
    .feedToggle3 {
      min-width: 100px;
      color: #323c46;
      border: 1px solid transparent;
      @media (max-width: 768px) {
        min-width: 80px;
      }
      &:hover {
        border-radius: 30px;
        border: 1px solid #0096ff;
        background-color: #ffffff;
      }
    }
    .feedToggle4 {
      min-width: 100px;
      color: #323c46;
      border: 1px solid transparent;
      @media (max-width: 768px) {
        min-width: 80px;
      }
      &:hover {
        border-radius: 30px;
        border: 1px solid #0096ff;
        background-color: #ffffff;
      }
    }
    .feedToggle5 {
      min-width: 100px;
      color: #323c46;
      border: 1px solid transparent;
      @media (max-width: 768px) {
        min-width: 80px;
      }
      &:hover {
        border-radius: 30px;
        border: 1px solid #0096ff;
        background-color: #ffffff;
      }
    }
  }
`;

// ScheduleForm component style
const StyledTime = styled('div')`
  .timeSchedule {
    font-size: 14px;
    margin-top: 4px;
    .css-1pahdxg-control {
      border: 1px solid #0096ff;
      box-shadow: none;
    }
    .css-1okebmr-indicatorSeparator {
      display: none;
    }
    svg {
      font-size: 21px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.54);
      &:hover {
        color: rgba(0, 0, 0, 0.54);
      }
    }
    #react-select-2-placeholder {
      color: #bbb8b8;
      font-size: 0.875rem;
    }
    .MuiOutlinedInput-root {
      height: 40px;
      padding: 0.5rem 0.75rem !important;
    }
  }
`;

// Feed  component style
const StyledFeedControl = styled(FormControl)`
  &.feedSettingCountry {
    flex-basis: 50%;
    flex-grow: 1;
    &:hover {
      .MuiFormLabel-root {
        color: #323c46;
      }
    }
    @media screen and (max-width: 1200px) {
      flex-basis: 50%;
      width: 100%;
    }
    .feedsAutocomplete {
      margin-top: 44px;
      .MuiOutlinedInput-root {
        height: 40px;
        padding: 0.5rem 0.75rem !important;
      }
    }
    label {
      transform: none !important;
      margin-top: 20px;
    }
  }
  &.importInputForm {
    flex-basis: 50%;
    flex-grow: 1;
    padding-left: 15px;
    &:hover {
      .MuiFormLabel-root.MuiInputLabel-root {
        color: #000000;
      }
    }
    @media screen and (max-width: 1200px) {
      flex-basis: 100%;
      width: 100%;
      padding-left: 0px;
    }
    .importInput {
      // margin-top: 25px;
      input {
        height: 17px;
      }
    }
  }
  .errorMsg {
    position: absolute;
    margin-left: 2px;
    margin-top: 2px;
    color: #e13f2a;
    opacity: 1;
    font-size: 12px;
  }
  .form-error {
    opacity: 1;
  }
`;

const StyledQualityContainer = styled(TableContainer)`
  border: 1px solid #dedede;
  &.feedSettingCountry {
    flex-basis: 50%;
    flex-grow: 1;
    &:hover {
      .MuiFormLabel-root {
        color: #323c46;
      }
    }

    @media screen and (max-width: 1200px) {
      flex-basis: 50%;
      width: 100%;
    }
    .feedsAutocomplete {
      margin-top: 44px;
      .MuiOutlinedInput-root {
        height: 40px;
        padding: 0.5rem 0.75rem !important;
      }
    }
    label {
      transform: none !important;
      margin-top: 20px;
    }
  }
  .MuiOutlinedInput-root {
    height: 40px;
  }
  .qualityTable {
    th {
      border-right: none;
      font-weight: 500;
    }
    .MuiTableRow-root:last-child .MuiTableCell-root {
      border-right: none;
    }
    tbody {
      th,
      td {
        border-right: none;
      }
    }
  }
`;

// Style for Affected items button
// const StyledAffectedItemsButton = styled(Button)(
//   ({ theme }) => `
// &.affectedItemsButton{
//   height: 44px;
//   min-width: 150px;
//   padding: 0rem 1rem 0rem 0.4rem;
//   .numberChip{
//     background-color:${theme.palette.primary.main};
//     color: ${theme.palette.background.main};
//     margin-right: 8px;
//     .MuiChip-label{
//       padding: auto 15px;
//     }
//   }
// }
// &.filterRemoved{
//       &:hover{
//         color: #FF823C !important;
//         border-color: #FF823C !important;
//     }
// }
// `
// );

const StyledAffectedItemsButton = styled(Button)(
  ({ theme }) => `
    position: relative; // For positioning pseudo-elements relative to the button
    height: 44px;
    min-width: 150px;
    padding: 0rem 1rem 0rem 0.4rem;
    overflow: hidden; // To contain the animated line within the button

    .numberChip {
      background-color: ${theme.palette.primary.main};
      color: ${theme.palette.background.default};
      margin-right: 8px;

      .MuiChip-label {
        padding: auto 15px;
      }
    }

    &.filterRemoved {
      &:hover {
        color: #FF823C !important;
        border-color: #FF823C !important;
      }
    }

 &.animate-border:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 20px;
  border: 2px solid ${theme.palette.primary.main};
  box-sizing: border-box;
  animation: move-border 3s linear forwards, hide-border 0s linear 3s forwards;
 }

@keyframes move-border {
  0% {
    clip-path: polygon(0% 0%, 0% 0%, 0% 0%, 0% 0%);
  }
  25% {
    clip-path: polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%);
  }
  50% {
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 100% 100%);
  }
  75% {
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
  }
  100% {
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
  }
}
  @keyframes hide-border {
      0% {
        opacity: 1;
      }
      100% {
        opacity: 0; /* Hide the border */
      }
    }
  `
);

const StyledMapTable = styled(Table)`
  &.importTable {
    th {
      &:first-of-type {
        width: 30%;
      }
      &:nth-of-type(2) {
        width: 40%;
      }
      &:nth-of-type(3) {
        width: 30%;
      }
    }
  }
  &.customTable {
    th {
      &:first-of-type {
        width: 30%;
      }
      &:nth-of-type(2) {
        width: 40%;
      }
      &:nth-of-type(3) {
        width: 10%;
      }
      &:nth-of-type(4) {
        width: 10%;
      }
      &:nth-of-type(5) {
        width: 10%;
      }
    }
  }
`;

const StyledLoadingCircle = styled(Box)(
  ({ theme }) => `
    position: relative;
    height: 40px;
    width:40px;
    marginLeft:-10px !important;
    animation: load 2s linear infinite;
    border-radius: 50%;
    overflow: hidden;
    background: conic-gradient(transparent, ${theme.palette.primary.main});
  

    &::before {
      content: '';
      position: absolute;
      z-index: 1;
      top: 50%;
      left: 50%;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background-color: #FFF;
      transform: translate(-50%, -50%);
    }
    @keyframes load {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }

  `
);

// Snackbar with cta
const StyledSnackbarStack = styled(Stack)`
  .MuiSnackbarContent-message {
    width: 100%;
  }
`;

export {
  StyledButtonModal,
  StyledModal,
  StyledMenu,
  StyledImported,
  StyledTableContainer,
  StyledButton,
  StyledEditMappingBox,
  StyledEnhcancedHead,
  StyledStack,
  StyledCategoryTableContainer,
  StyledEnhanceTable,
  StyledItem,
  StyledToggleButtonGroup,
  StyledTime,
  StyledFeedControl,
  StyledQualityContainer,
  StyledMobileList,
  StyledFAModal,
  StyledAffectedItemsButton,
  StyledMapTable,
  StyledSnackbarStack,
  StyledLoadingCircle,
  StyledCustomColumnModel,
};
